import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage3Avaleht from "./pages/Webpage3Avaleht";

var baseURL = 'https://web.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMzljNzYwNzM4Y2MzOGM2YjFlMjQ1Y2E1ODcxODIyN2VmNWRlZWJlOTc0OTY1NjU5ZTNlMWMwMTE2ZjdlMmYwMTcwZTZiMTdhZjc1YjVhYjAiLCJpYXQiOjE3MjY3MzYxNjUuMTE2MzQ3LCJuYmYiOjE3MjY3MzYxNjUuMTE2MzUyLCJleHAiOjE3MjY4MjI1NjUuMTEwNDAzLCJzdWIiOiI2NiIsInNjb3BlcyI6W119.OxtkLKQz8GANe4_p9p8ynBs5gUvjyFox7XKNp--w4k_8dplP6FNrTFT9mFoTihzC-f1HDfscSyU6tP6PTIDQN6Gg1ZidFxYEclew8wZyUs-Rn7M-SPYmf1zGHLrnYnTfiLNZ4um9aBh4we6WMGzCr-DOqolOkqP6HPozDC_diD3LFv57qc7hLKd_2kV0cg0fS4t306jeiB2j7KymiTJaosu2e68SkwoO5_ccVZPu_ePPxh-GMYzW2wz4U4Khwy80PO0bfuJ_2IXQkx7zcvpuImi67Apbh7RMvfuhxZB8K25b0Fkop0Ml9wMnNWkp7sbvAmsp0VI_RONHQXYo4OBmkLbTbBbtM-5WGSKErgXX9u57cW4NCfsn8mZbrR4IGaZZrsnqbOJDjMAf3u21WylITd7SQDBqMKmua6Ck5cdNlHjOYTx4EyYWyR7GVaxs9tJLbJvXw6le-zkfsn2ddbm5I17AJyPDwr8rCRaDkygvNfTHDuhctRDXd8KFYOtYwTHZkiDj94lMJeaNkM1i58Gyjyxe6Fyj8USJ2sljQSbtwF7jygL-VTnCXPuWtK1-zVWOUAE3gQrdNtYn4u7QbzzH_ChBGayjGC-arZbNygGy973-mkdP4wSzt8WNZNcdeoy6tGmb3jxVbCERwvLX516MKX9wdTLfPR9a8rQWwbDeB2E',
    'Access-api-key': "eyJpdiI6IlFaZkd3U1QrU0FDRDkzUlJwcFNab2c9PSIsInZhbHVlIjoiZlpjWExIdzg2MFJ3M1pxNkRweEN4dz09IiwibWFjIjoiOWVkNzY3YjZhMTliYmUyNDQ3OTcwMWUzNzQyMWU2MmEyY2UwNjZiMzk4NzliNmIwZjFjYmE1YWExOTgwZjA0MyIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IkdyaWluWGVZQUhYUThBTzJLeG9yeHc9PSIsInZhbHVlIjoiaXlaN09adzNXMUdqWmlUalAwNTdjQT09IiwibWFjIjoiZTE3ZjhkY2RiNjJhNTc5ZGJlODI3YjRkMzMxMTRmYWJhYmM2OTc4ZDU1YTNhM2U0NmRlMDZiOWJiMjc3ODA5ZiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: ""
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage3Avaleht />} exact />;
                                                                
                                                                <Route path="*" element={<Webpage3Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}